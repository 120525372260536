import React, { Component } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "../../components/MobileMenu";
import { BASE_URL } from "../../constant/constants";

export default class Header2 extends Component {
  state = {
    isSearchShow: false,
  };

  searchHandler = () => {
    this.setState({
      isSearchShow: !this.state.isSearchShow,
    });
  };

  render() {
    const ClickHandler = () => {
      window.scrollTo(10, 0);
    };

    return (
      <header id="header" className={this.props.topbarNone}>
        <div className={`wpo-site-header ${this.props.hclass}`}>
          <nav className="navigation navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                  <div className="mobail-menu">
                    <MobileMenu />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-6">
                  <div className="navbar-header">
                    <Link
                      onClick={ClickHandler}
                      className="navbar-brand"
                      to={`/`}
                    >
                      <img
                        style={{
                          width: "115px",
                          marginLeft: "50px",
                          height: "75px",
                        }}
                        src={`${BASE_URL}${this.props.Logo}`}
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-7 col-md-1 col-1">
                  <div
                    id="navbar"
                    className="collapse navbar-collapse navigation-holder"
                  >
                    <button className="menu-close">
                      <i className="ti-close"></i>
                    </button>
                    {this.props.menu.map((n) => (
                      <ul className="nav navbar-nav mb-2 mb-lg-0">
                        <li className="menu-item-has-children">
                          {n.name === "Home" ? (
                            <Link onClick={ClickHandler} to={`/`}>
                              {n?.name === "Home" ? n.name : ""}
                            </Link>
                          ) : (
                            <Link
                              onClick={ClickHandler}
                              to={`/${n.name.toLowerCase()}/${n.id}`}
                            >
                              {n?.name}
                            </Link>
                          )}

                          <ul
                            className="sub-menu"
                            style={{
                              display:
                                n.children.length === 0 ? "none" : "block",
                            }}
                          >
                            {n.children.map((n) => (
                              <li>
                                <Link
                                  onClick={ClickHandler}
                                  to={`/${n.name.toLowerCase()}/${n.id}`}
                                >
                                  {n.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                          {/* <ul className="sub-menu">
                            <li>
                              <Link onClick={ClickHandler} to="/home">
                                Home Charity
                              </Link>
                            </li>
                            <li>
                              <Link onClick={ClickHandler} to="/home2">
                                Home Education
                              </Link>
                            </li>
                            <li>
                              <Link onClick={ClickHandler} to="/home3">
                                Home Wildlife
                              </Link>
                            </li>
                            <li>
                              <Link onClick={ClickHandler} to="/home4">
                                Home Ocean Polution
                              </Link>
                            </li>
                            <li>
                              <Link onClick={ClickHandler} to="/home5">
                                Home World Pandemic
                              </Link>
                            </li>
                            <li>
                              <Link onClick={ClickHandler} to="/home6">
                                Home Nature
                              </Link>
                            </li>
                            <li>
                              <Link onClick={ClickHandler} to="/home7">
                                Home Nature S2
                              </Link>
                            </li>
                          </ul> */}
                        </li>
                      </ul>
                    ))}
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-2">
                  <div className="header-right">
                    <div
                      style={{
                        display: "flex",
                        right: "90px",
                        top: "7px",
                      }}
                    >
                      <p style={{ color: "whitesmoke" }}>Lg </p>
                      <div id="google_translate_element"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}
